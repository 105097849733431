var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3d6b2ca823c20f7cc3eadd34c96259aedbfc502f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { getSentryInitDefaults } from '@ours/web-utils';
import * as Sentry from '@sentry/nextjs';

const stage = process.env.NEXT_PUBLIC_STAGE || process.env.STAGE || 'unknown';

Sentry.init({
  dsn: 'https://8ba26343fd07423cba87b6462c01303d@o1064262.ingest.sentry.io/6055027',
  ...getSentryInitDefaults({ stage }),
});
